import React, { useState } from 'react';
import './App.css';

function App() {
  const [showAbout, setShowAbout] = useState(false);

  // Toggle About message on click
  const handleAboutClick = () => {
    setShowAbout(!showAbout);
  };

  return (
    <div className="App">
      {/* Logo in the top-left */}
      <div className="logo">
        01
      </div>

      {/* About link in the bottom-left */}
      <button className="about-link" onClick={handleAboutClick}>
        What is 01atom?
      </button>

      {/* Main content */}
      <div className="content">
        {!showAbout ? (
          <>
            <h1>
              01atom<span className="blinking-underscore">_</span>
            </h1>
          </>
        ) : (
          <p className="about-message">
            Just a bunch of engineers, physicists, and founders working with quantum sensors.
            <br />
            <br />
            🧲🧭📍<span className="blinking-emoji">🤫</span>
            <br />
            <br />
            In stealth mode - contact: info@01atom.com
            <br />
            <br />
            Home is Copenhagen ❤️
          </p>
        )}
      </div>
    </div>
  );
}

export default App;
